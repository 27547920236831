exports.components = {
  "component---src-pages-findings-index-jsx": () => import("./../../../src/pages/findings/index.jsx" /* webpackChunkName: "component---src-pages-findings-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-publications-index-jsx": () => import("./../../../src/pages/publications/index.jsx" /* webpackChunkName: "component---src-pages-publications-index-jsx" */),
  "component---src-pages-tools-coping-strategies-index-jsx": () => import("./../../../src/pages/tools/coping-strategies/index.jsx" /* webpackChunkName: "component---src-pages-tools-coping-strategies-index-jsx" */),
  "component---src-pages-tools-inventory-index-jsx": () => import("./../../../src/pages/tools/inventory/index.jsx" /* webpackChunkName: "component---src-pages-tools-inventory-index-jsx" */),
  "component---src-templates-finding-index-jsx": () => import("./../../../src/templates/finding/index.jsx" /* webpackChunkName: "component---src-templates-finding-index-jsx" */),
  "component---src-templates-frontpage-index-jsx": () => import("./../../../src/templates/frontpage/index.jsx" /* webpackChunkName: "component---src-templates-frontpage-index-jsx" */),
  "component---src-templates-news-index-jsx": () => import("./../../../src/templates/news/index.jsx" /* webpackChunkName: "component---src-templates-news-index-jsx" */),
  "component---src-templates-page-index-jsx": () => import("./../../../src/templates/page/index.jsx" /* webpackChunkName: "component---src-templates-page-index-jsx" */),
  "component---src-templates-page-with-header-index-jsx": () => import("./../../../src/templates/page-with-header/index.jsx" /* webpackChunkName: "component---src-templates-page-with-header-index-jsx" */),
  "component---src-templates-publication-index-jsx": () => import("./../../../src/templates/publication/index.jsx" /* webpackChunkName: "component---src-templates-publication-index-jsx" */),
  "component---src-templates-strategy-category-index-jsx": () => import("./../../../src/templates/strategy-category/index.jsx" /* webpackChunkName: "component---src-templates-strategy-category-index-jsx" */),
  "component---src-templates-strategy-index-jsx": () => import("./../../../src/templates/strategy/index.jsx" /* webpackChunkName: "component---src-templates-strategy-index-jsx" */)
}

